import "./style.css"

function Recipeitem(props) {
    const {id, title, image} = props;
    return(
        <div key={id} className="foodBox">
            <div className="foodImage"><img src={image} alt="imag"/></div>
            <h3 className="foodTitle">{title}</h3>
        </div>
    )
}

export default Recipeitem;