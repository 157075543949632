import { useState } from 'react';
import './styles.css'

function Search(props){

    // console.log(props)

    const {onChange} = props;


    const handleSubmit = (event) =>{
        event.preventDefault()
        onChange(event.target.value);

    }

    return(
        <>
           <div  className="search-from-wrap">
            <input type="search" onChange={handleSubmit} className="search-input" name="search" placeholder="Search reciepe" id="search"/>
           </div>
        </>
    )
}

export default Search;