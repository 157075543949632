import { useState,useMemo } from "react";
import Search from "../../components/search";
import Recipeitem from "../../components/recipeitem";
import "./styles.css";
import { debounce } from "lodash";
const dummydata = "dummydata";

async function getReceipes(query) {
    const apiResponse = await fetch(
        `https://api.spoonacular.com/recipes/complexSearch?apiKey=70c8a7eb2f2b4e929e912b37917c0d53&query=${query}`
    );
    const result = await apiResponse.json();
    const { results } = result;
    return results
}

function Homepage() {
    // loading state

    const [loadingState, setLoadingState] = useState(false)

    // save results that we received from api

    const [receipes, setRecipes] = useState([])
    
    const debounceSearch = useMemo(
        () =>
          debounce(async (value) => {
            setLoadingState(true);
            const results = await getReceipes(value);
            setRecipes(results);
            // console.log(results)
            setLoadingState(false);
          }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
      );

    const getDataFromSearchComponent = (getData) => {
        debounceSearch(getData)
        // console.log(getData)
    };

    // console.log(loadingState, receipes, 'loadingState, receipes');
    return (
        <div className="homepage-wrapper">
            <Search
                onChange={getDataFromSearchComponent}
            />
            {
                loadingState && <div className="">Loading receipes ! Please wait.</div>
            }

            {

                <div className="food-main-wraper">
                    {
                        receipes && receipes.length > 0
                        && receipes.map((item,i) => <Recipeitem id={item.id} title={item.title} image={item.image} item={item} key={i} />)
                    }
                </div>
            }

            
        </div>
    );
}

export default Homepage;